import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../earphoneactivation/earphoneactivation.component.css";
// import Backpack from "../../assets/bagpack.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import Navbar from "../header/header.component";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { TextField, InputAdornment, Tooltip } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import { switchUrls } from "../../api/index.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import imageCompression from "browser-image-compression";
import setCanvasPreview from "../../setcanvasPreview.js";
import Sidebarcontent from "../sidebar/sidebar.component.jsx";
// import earphone from "../../assets/earphone.png";
// import Loader from "../../assets/loader.gif";
import Footer from "../footer/footer.component.jsx";

const baseURL = switchUrls("earphone");
const basegatewayURL = switchUrls("gateway");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");

export default class EarphoneDetails extends React.Component {
  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      //imageUrl: null,
      // bag_imagelocation: null,
      // bag_imagename: null,
      // selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      Date_purchase: "",
      earphone_brand: "tag8",
      earphone_model: "in-ear",
      earphone_color: "white",
      valuable_type: "Gadget",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      showLoader: true,
      display_name: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/earphone.png",
      imagemodal: false,
      crop: "",
      uploaderror: false,
      earphone_imagelocation: null,
      imageName: "",
      show: false,
      show5: false,
      tagNotFound: false,
      alreadyActivated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
  }
  cropsetup(e) {
    // console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    // console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob(async (blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      const imageDimension = await this.imageSize(file);
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      });
      console.log(
        "image size after compression:",
        compressedFile.size / (1024 * 1024)
      );
      this.setState({
        selectedImage: compressedFile,
        imageUrl: URL.createObjectURL(compressedFile),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
        showLoader: false,
      });
    });
  }

  formatDateToDDMMYY(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/earphone/getearphoneDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        // console.log(response);
        const dateofpurchase = new Date(response?.data?.Date_purchase);
        const yyyy = dateofpurchase?.getFullYear();
        let mm = dateofpurchase?.getMonth() + 1; // Months start at 0!
        let dd = dateofpurchase?.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;
        this.setState({
          // bag_imagelocation: response.data.bag_imagelocation,
          // bag_imagename: response.data.bag_imagename,
          tag_number: response.data.tag_number,
          tag_status: response.data.tag_status,
          Date_purchase: formattedToday,
          earphone_imagelocation: response.data?.earphone_imagelocation,
          display_name: response.data?.display_name,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }
handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
   
   handleNotFound = () => {
    this.setState({ tagNotFound: false });
  };

  handleActivated = () => {
    this.setState({ alreadyActivated: false });
  };
  activateTag() {
    const { tag_number } = this.state;
    const state = {
      tag_number: tag_number.toUpperCase(),
    };
    this.setState({ showLoader: true });
    const loaderTimer = setTimeout(() => {
      this.setState({ showLoader: false });
    }, 5000);

    if (
      ["BDC", "BDT", "DOL", "DPT", "TSW"].includes(tag_number.substring(0, 3))
    ) {
      this.setState({ walletDialogOpen: true });
    } else {
      axios
        .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
          headers: {
            Authorization: this.props.token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const productcategory = res.data.tag_data.product_category;
          sessionStorage.removeItem("tag_number");

          const routes = {
            "QR PET": `/pet-activation/${tag_number}`,
            "QR Key": `/key-activation/${tag_number}`,
            "QR bag": `/bag-activation/${tag_number}`,
            "QR Bag": `/bag-activation/${tag_number}`,
            "E-Bag": `/bag-activation/${tag_number}`,
            "QR Medical": `/bag-activation/${tag_number}`,
            "QR Passport": `/passport-activation/${tag_number}`,
            "E-Passport": `/passport-activation/${tag_number}`,
            "QR Gadget": `/earphone-activation/${tag_number}`,
            "Generic": `/generic-activation/${tag_number}`,
            "QR Wallet": `/wallet-activation/${tag_number}`,
            "QR Human": `/human-activation/${tag_number}`,
            "QR HUMAN": `/human-activation/${tag_number}`,
            "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
          };

          if (routes[productcategory]) {
            window.location.href = routes[productcategory];
          }
        })
        .catch((error) => {
          clearTimeout(loaderTimer);
          this.setState({ showLoader: false });
          const responseData = error.response.data;
           console.log(responseData,"daraaa")
          if (responseData === "Tag not found by the specified number.") {
            this.setState({ tagNotFound: true });
            // this.props.CheckLfTag(state.tag_number);
          } else if (responseData === "Tag is already activated.") {
            this.setState({ show: false, alreadyActivated: true });
          }
        });
    }
  }
  handleCloseQRModal() {
    this.setState({ show5: false });
  }

  handleQRResult(result, error) {
    if (result) {
      const activateTag = result?.text
        .split("/")
        [result?.text.split("/").length - 1].toUpperCase();
      this.setState({ activateTag, show5: false });
    }
    if (error) {
      console.error(error.message);
    }
  }

  renderQRModal() {
    return (
      <Dialog
        open={this.state.show5}
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseQRModal}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={this.handleQRResult}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  async handleChange(event) {
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log("image original size:", sizeinmb);
      this.setState({ imagemodal: true });
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
        isuploading: true,
        imageName: event.target.files[0].name,
      });
    } else {
      console.log("failed");
    }
  }

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  deleteimage = (e) => {
    axios
      .post(`${baseURL}/api/earphone/deleteearphoneimage`, this.state)
      .then((response) => {
        // console.log(response);
        if (response.data?.status) {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image removed",
            earphone_imagelocation: null,
            earphone_imagename: null,
          });
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };

  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state);
    this.setState({ showLoader: true });
    axios
      .post(
        `${baseURL}/api/earphone/editearphoneDetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        // console.log(response);
        if (response.data === "Earphone Details Updated Successfully") {
          if (this.state?.selectedImage) {
            const fd = new FormData();
            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);
            axios
              .post(`${baseURL}/api/earphone/earphoneimageupload`, fd)
              .then((res) => {
                // console.log(res);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Earphone image Uploaded",
                });
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              })
              .catch((error) => {
                console.error(error.message);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Earphone image Upload failed",
                });
                this.setState({ showLoader: false });
              })
              .finally(() => {
                this.setState({ showLoader: false });
              });
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        } else if (response.data === "Earphone Details Update Failed") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          this.setState({ showLoader: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Earphone Details Update Failed",
        });
        this.setState({ showLoader: false });
      });
  };
  // handleShow() {
  //   window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  // }
  handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }
  render() {
    const {
      imageUrl,
      tag_number,
      Date_purchase,
      earphone_brand,
      earphone_color,
      warranty_period,
      valuable_type,
      sidebar,
      subMenu,
      earphone_imagelocation,
      imageUrlvector,
      imagemodal,
      uploaderror,
      crop,
      message,
      display_name,
      show,
      setShow5,
      tagNotFound, 
      alreadyActivated,
    } = this.state;
    const today = () => {
      var dtToday = new Date();
      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var today = year + "-" + month + "-" + day;
      return today;
    };
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />
        <div className="bagActivation-main-wrapper">
          <div className="bagActivation-left-wrapper">
            <Sidebarcontent
              handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound}
            />
            <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => setShow5(true)}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </button>
                </div>
              </DialogContent>
            </Dialog>
              <Dialog
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          open={tagNotFound}
          onClose={this.handleNotFound}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="small__popup">
            <h3 style={{ textAlign: "center" }}>Tag number not found</h3>
            <DialogActions>
              <button
                className="small_pop_btn"
                onClick={this.handleNotFound}
              >
                OK
              </button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* Already Activated Dialog */}
        <Dialog
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          open={alreadyActivated}
          onClose={this.handleActivated}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="small__popup">
            <h3 style={{ textAlign: "center" }}>Tag is already activated</h3>
            <button
              className="small_pop_btn"
              onClick={this.handleActivated}
            >
              OK
            </button>
          </DialogContent>
        </Dialog>
          </div>
        </div>
        <div className="bag-container">
          <div className="background-image">
            <div className="bag-activation-container">
              <p>
                <strong>Earphone Details</strong>
              </p>

              <div className="bag-activation-form-container">
                <form className="bag-activation-form">
                  <div className="editPetDetails-user-img">
                    {imageUrl?.length ? (
                      <>
                        <img
                          alt="imageurl"
                          src={imageUrl}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : earphone_imagelocation ? (
                      <>
                        <img
                          alt="imagelocation"
                          src={earphone_imagelocation}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          alt="imageyrlvector"
                          src={imageUrlvector}
                          className="editPetDetails-image"
                        />
                      </>
                    )}
                    <div className="editPetDetails-edit-btn-wrap">
                      <label
                        htmlFor="upload"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>

                      <input
                        id="upload"
                        className="editPetDetails-input-user-img"
                        type="file"
                        onChange={this.handleChange}
                      />
                      {earphone_imagelocation ? (
                        <>
                          <label
                            className="editPetDetails-delete-button-image "
                            onClick={this.deleteimage}
                          >
                            <AiIcons.AiFillDelete /> Remove
                          </label>
                        </>
                      ) : null}
                    </div>
                    <div
                      className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                      style={{ gap: "6px" }}
                    >
                      <img
                        alt="qr"
                        src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ marginTop: "15px" }}>
                        <b>
                          {tag_number && tag_number !== "-"
                            ? tag_number
                            : "N/A"}
                        </b>
                      </p>
                    </div>
                  </div>

                  <div>
                  <div className="bagactivation-label-container">
                      <TextField
                        type="text"
                        placeholder="Gadgets"
                        label="Valuable Type"
                        name="valuable_type"
                        value={valuable_type}
                        // className="bagactivation-input-tag"
                        InputProps={{
                          readOnly: true,
                          // startAdornment: (
                          //   <InputAdornment position="start">
                          //     Type:
                          //   </InputAdornment>
                          // ),
                        }}
                      />
                    </div>
                    <div className="bagactivation-label-container">
                      <Tooltip
                        describeChild
                        title="This name will appear on the scan page when the tag is scanned."
                        leaveTouchDelay={3000}
                        enterTouchDelay={50}
                      >
                        <TextField
                          required
                          type="text"
                          variant="outlined"
                          name="display_name"
                          label="Display Name"
                          value={display_name}
                          onChange={this.changeHandler}
                          className="bagactivation-flex-item"
                          style={{ width: "100%" }}
                        />
                      </Tooltip>
                    </div>
                    <div className="bagactivation-label-container">
                      <TextField
                        type="date"
                        // inputProps={{ max: today }}
                        variant="outlined"
                        label="Earphone Purchase Date"
                        // className="bagactivation-input-tag"
                        name="Date_purchase"
                        value={Date_purchase}
                        onChange={this.changeHandler}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                    

                    <div className="bagactivation-btn-grp">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                        onClick={this.submitHandler}
                      >
                        SUBMIT
                      </Button>

                      <Button
                        variant="contained"
                        type="button"
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                        className="submit-login bg-white mt-2"
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                  {this.state.showLoader && (
                    <div className="loader-container">
                      <div className="loader">
                        <img
                           src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            }
                          alt="Loading..."
                          loading="lazy"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button
                    onClick={() => {
                      this.setState({ showLoader: true });
                      this.handelimagecrop();
                    }}
                  >
                    Submit
                  </Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
        {this.renderQRModal()}
      </>
    );
  }
}
